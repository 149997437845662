/*! themes/_green.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Green theme variables and styles (Green theme is used in "Landing kit v2" )
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Theme color variables
1. Theme gradient
2. Box shadows
3. Theme specific Hero
=============================================================================
***/

/* ==========================================================================
0. Theme color variables
========================================================================== */
$primary: #ff0000;
$secondary: #000000;
$accent: #CF0000;

// $primary: #00b289;
// $secondary: #00b289;
// $accent: #06f4b6;

/* ==========================================================================
1. Theme Gradient
========================================================================== */
$webkit-hero-gradient: -webkit-linear-gradient(to right, $accent, $secondary); 
$hero-gradient: linear-gradient(to right, $accent, $secondary); 

/* ==========================================================================
2. Box shadows
========================================================================== */
//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba(255, 0, 0, 0.72);
$primary-shadow-to: rgba(255, 0, 0, 0.5);
$primary-box-shadow:  0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba(0, 0, 0, 0.72);
$secondary-shadow-to: rgba(0, 0, 0, 0.5);
$secondary-box-shadow:  0 14px 26px -12px $secondary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba(255, 0, 0, 0.52);
$accent-shadow-to: rgba(255, 0, 0, 0.3);
$accent-box-shadow:  0 14px 26px -12px $accent-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;

/* ==========================================================================
3. Theme specific Hero
========================================================================== */
.hero, .section {
    &.is-theme-primary {
        background-color: $primary;
        .title {
            color: $white;
        }
        .subtitle {
            color: $white;
        }
    }
}

/* v3 page */


/* ==========================================================================
3. Help center
========================================================================== */

//Help page
.help-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        max-height: 45px;
        max-width: 45px;
        padding-right: 20px;
        border-right: 1px solid $white;
    }
    .title {
        color: $white;
        padding-left: 20px;
        position: relative;
        top: -2px;
    }
}

.help-subheader {
    padding: 20px 0 30px 0;
}

//Search input wrapper
.help-search-wrapper {
    position: relative;
    width: 100%;
    //input
    input {
        width: 100%;
        padding: 20px 32px 21px 59px;
        background: rgba(255,255,255,0.2);
        border: none;
        outline: none;
        color: rgba(255,255,255,0.7);
        font-size: 18px;
        border-radius: 4px;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.03);
        -webkit-transition: background .4s, box-shadow .2s;
        transition: background .4s, box-shadow .2s;
        //placeholders
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: rgba(255,255,255,0.7) !important;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: rgba(255,255,255,0.7) !important;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: rgba(255,255,255,0.7) !important;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: rgba(255,255,255,0.7) !important;
        }
        //focused input
        &:focus {
            box-shadow: 0 10px 20px rgba(0,0,0,0.14);
            background: $white;
            color: $blue-grey;
            + i {
                color: $muted-grey;
            }
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $placeholder !important;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: $placeholder !important;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: $placeholder !important;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: $placeholder !important;
            }
        }
    }
    //search icon
    i {
        font-size: 2rem;
        color: rgba(255,255,255,0.7);
        position: absolute;
        top: 18px;
        left: 18px;
    }
}

//Help section layout
.section.help-section {
    background: $background-grey;
    //Card
    .category-card {
        box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
        .card-body {
            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .icon {
                    width: 110px;
                    height: 110px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i {
                        font-size: 3.5rem;
                        color: $icon-grey !important;
                    }
                }
                //Inner section
                .inner-content {
                    padding-left: 25px;
                    .title {
                        margin-bottom: 10px !important;
                        font-weight: 400;
                        color: $primary;
                    }
                    .inner-text {
                        color: $muted-grey !important;
                    }
                    //Meta
                    .card-meta {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 12px;
                        .small-avatar {
                            height: 34px;
                            width: 34px;
                            border-radius: 50%;
                            margin-right: 10px;
                            border: 2px solid $white;
                            position: relative;
                            &:not(:first-child) {
                                margin-left: -19px;
                            }
                            &:first-child {
                                z-index: 3;
                            }
                            &:nth-child(2) {
                                z-index: 2;
                            }
                            &:nth-child(3) {
                                z-index: 1;
                            }
                            &:nth-child(4) {
                                z-index: 0;
                            }
                            &.is-more {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: $white;
                                background: $muted-grey;
                                font-weight: 500;
                                font-size: 13px;
                                span {
                                    position: relative;
                                    top: -2px;
                                    left: -1px;
                                }
                            }
                        }
                        .meta-info {
                            color: $muted-grey !important;
                            .articles-number {
                                font-size: 13px; 
                            }
                            .authors {
                                font-size: 13px; 
                                span {
                                    color: $blue;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//Breadcrumbs
.breadcrumbs {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: $muted-grey;
    ul {
        margin: 0 !important;
        li {
            display: inline-block;
            list-style: none;
            margin: 0 0 0 10px;
            &:first-child:before {
                content: '';
                margin-left: -20px;
            }
            &:before {
                content: "\f105";
                font-family: "FontAwesome";
                color: #a0a0a0;
                font-size: 14px;
                margin-right: 15px;
            }
        }
    }
    a {
        font-weight: 500;
        color: $primary;
    }
}

/****/

//Follow card 
.follow-card {
    .header {
        position: relative;
        width: 100%;
        max-height: 100px;
        .cover {
            width: 100%;
        }
        .avatar {
            position: absolute;
            left: 20px;
            bottom: -45px;
            border-radius: 50%;
            border: 5px solid white;
            height: 90px;
            width: 90px;
        }
        span {
            position: absolute;
            right: 20px;
            top: 20px;
            font-weight: 700;
            color: $white;
            span {
                font-size: 90%;
                font-weight: 500;
                right: 0;
            }
        }
    }
    button {
        margin: 20px;
    }
    &.is-minimal {
        .header {
            background: $blue;
            height: 100px;
        }
        .twitter-icon {
            position: absolute;
            background: $blue;
            left: 20px;
            bottom: -45px;
            border-radius: 50%;
            border: 5px solid white;
            height: 90px;
            width: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            i { 
                color: $white;
                font-size: 40px;
            }
        }
    }
}

/* ==========================================================================
5. Help category
========================================================================== */
.help-container {
    position: relative;
    //grey background frame
    .shadow-bg {
        width: 100%;
        &:before {
            content: ' ';
            position: absolute;
            display: block;
            top: 50px;
            left: 0;
            bottom: 0;
            right: 0;
            background: #ebeef1;
            border-radius: 4px;
            -webkit-transition: background .3s;
            transition: background .3s;
            z-index: 0;
            margin: 0 10px;
        }
    }
    //Header
    .category-header {
        padding: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        top: 20px;
        z-index: 2;
        .icon {
            width: 110px;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                font-size: 3.5rem;
                color: $icon-grey !important;
            }
        }
        //Inner section
        .inner-content {
            padding-left: 25px;
            .title {
                margin-bottom: 10px !important;
                font-weight: 400;
                color: $primary;
            }
            .inner-text {
                color: $muted-grey !important;
            }
            //meta
            .card-meta {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top: 12px;
                .small-avatar {
                    height: 34px;
                    width: 34px;
                    border-radius: 50%;
                    margin-right: 10px;
                    border: 2px solid $white;
                    &:not(:first-child) {
                        margin-left: -19px;
                    }
                    &:first-child {
                        z-index: 3;
                    }
                    &:nth-child(2) {
                        z-index: 2;
                    }
                    &:nth-child(3) {
                        z-index: 1;
                    }
                    &:nth-child(4) {
                        z-index: 0;
                    }
                    &.is-more {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $white;
                        background: $muted-grey;
                        font-weight: 500;
                        font-size: 13px;
                        span {
                            position: relative;
                            top: -2px;
                            left: -1px;
                        }
                    }
                }
                .meta-info {
                    color: $muted-grey !important;
                    .articles-number {
                        font-size: 13px; 
                    }
                    .authors {
                        font-size: 13px; 
                        span {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
    //List of help articles
    .articles-list {
        position: relative;
        margin: 30px;
        background: $white;
        width: calc(100% - 60px);
        border-radius: 4px;
        border: 1px solid $border-grey;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,0.05);
        //list item
        .help-article {
            padding: 30px;
            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .inner-content {
                    .title {
                        margin-bottom: 10px !important;
                        font-weight: 400;
                        color: $primary;
                    }
                    .inner-text {
                        color: $muted-grey !important;
                    }
                    .card-meta {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 12px;
                        .small-avatar {
                            height: 34px;
                            width: 34px;
                            border-radius: 50%;
                            margin-right: 10px;
                            border: 2px solid $white;
                            position: relative;
                        }
                        .meta-info {
                            color: $muted-grey !important;
                            line-height: 1.1rem;
                            .author {
                                font-size: 13px; 
                                span {
                                    color: $blue;
                                }
                            }
                            .status {
                                font-size: 12px;
                                color: $title-grey;
                            }
                        }
                    }
                }
            }
            &:not(:last-child) {
                border-bottom: 1px solid $border-grey;
            }
        }
    }
}

/* ==========================================================================
7. Testimonials carousel
========================================================================== */
.people-carousel {
    .people-carousel-item {
        .item-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center; 
        }
        .image-column {
            width: 60%;
            display: block;
            position: relative;
        }
        .content-column {
            width: 40%;
            display: block;
            position: relative;
        }
        .carousel-img {
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .testimonial-title {
        font-size: 1.7rem;
        color: $blue-grey;
        font-family: 'Nexa Bold', sans-serif;
        font-weight: 600;
        position: relative;
        i {
            position: absolute;
            top: -25px;
            right: 0;
            color: $primary;
        }
    }
    .testimonial-content {
        p {
            padding-bottom: 10px;
        }
    }
    .customer-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .customer-avatar img {
            height: 45px;
            width: 45px;
            border-radius: 50%;
            border: 1px solid $fade-grey;
        }
        .customer-block {
            margin: 0 10px;
            position: relative;
            .name {
                font-family: 'Nexa Light', sans-serif;
                font-weight: 600;
                color: $blue-grey;
            }
            .position {
                font-size: 90%;
                color: $title-grey;
            }
        }
        .company-logo {
            max-width: 100px !important;
            margin-left: auto;
        }
    }
    .mobile-spacer {
        padding-bottom: 80px;
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0 !important;
    }
    .slick-dots {
        bottom: 40px;
        right: 18%;
        width: auto;
    }
}

//Testimonials carousel media query (mobile)
@media (max-width: 767px) {
    .people-carousel {
        .content-column {
            width: 100% !important;
        }
        .testimonial-title {
            font-size: 1.4rem; 
        }
        .slick-dots {
            left: 0 !important;
            right: 0 !important;
        }
    }
}

/* ==========================================================================
10. Process blocks section
========================================================================== */
.process-block {
    position: relative;
    text-align: center;
    .process-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5rem;
        .icon-wrapper {
            position: relative;
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &.has-line:after {
        content: '';
        height: 4px;
        width: 80px;
        background: $fade-grey;
        position: absolute;
        top: 88px;
        right: -14%;
        border-radius: 50px;
    }
    .process-number {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        font-size: 14px;
        font-weight: bolder;
        position: absolute;
        top: 13px;
        right: 0;
        background: $primary;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .process-info {
        display: flex;
        justify-content: center;
        align-items: center;
        .step-number {
            font-size: 8rem;
            font-weight: bolder;
            color: $fade-grey;
            margin-right: 15px;
        }
        .details {
            text-align: left;
        }
        .motto {
            font-size: 2.4rem;
            font-weight: 700;
            color: $blue-grey;
        }
        .description {
            font-size: 95%;
            color: $muted-grey;
        }
    }
}

//Process blocks section media query (mobile)
@media (max-width: 768px) {
    .process-block.has-line:after {
        display: none;
    }
}

//CTA
.cta-text {
    font-family: 'Nexa Bold', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}

/* ==========================================================================
14. Blog main page
========================================================================== */

.blog-hero {
    padding: 8rem 1.5rem;
}

.flex-card.is-post {
    border-radius: 6px;
    &.is-pulled-top {
        margin-top: -180px;
    }
    .header {
        min-height: 170px;
        width: 100%;
        background: $primary;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        h2.post-title {
            font-family: 'Nexa Bold', sans-serif;
            position: relative;
            color: $white;
            font-size: 1.6rem;
            font-weight: 500;
            padding: 0 20px;
            z-index: 1;
        }
        h4.post-subtitle {
            position: relative;
            color: $white;
            font-family: 'Nexa Light', sans-serif;
            font-size: 1.1rem;
            font-weight: 400;
            padding: 10px 20px;
            z-index: 1;
        }
        .author-avatar {
            position: absolute;
            right: 60px;
            bottom: -50px;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 5px solid $white;
            z-index: 1;
            display: block;
            img {
                border-radius: 50%;
                position: relative;
                left: -0.5px;
                transform: scale(1.02);
            }
        }
        .header-overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: $coal;
            opacity: 0.5;
            z-index: 0;
        }
    }
    .post-body {
        padding: 20px;
        div span {
            font-size: 90%;
            color: $muted-grey;
        }
        div a.author-name {
            font-family: 'Nexa Light', sans-serif;
            color: $blue-grey;
            &:hover {
                color: $primary;
            }
        }
        p {
            padding: 10px 0;
            color: $blue-grey;
        }
        hr {
            background-color: $fade-grey;
        }
        a {
            color: $primary;
            &.button {
                color: $muted-grey;
                font-weight: 500;
                font-family: 'Nexa Bold', sans-serif;
                &:hover {
                    color: $blue-grey !important;
                }
            }
            &:hover {
                opacity: 0.7;
            }
        }
        small {
            color: $muted-grey;
            font-size: 0.9rem;
        }
        .footer-details {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .likes-count, .comments-count {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                i {
                    font-size: 1.5rem;
                    font-weight: 900;
                    //position: relative;
                    //top: 4px;
                    color: $title-grey;
                }
                .stat {
                    font-size: 90%;
                    color: $title-grey;
                    font-weight: 500;
                    padding: 0 4px
                }
            }
            .likes-count {
                margin-right: 10px;
            }
            .comments-count {
                margin: 0 10px;
            }
        }
    }
}

//Like button in blog pages
.fab-btn {
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    padding: 16px;
    border: none;
    border-radius: 50%;
    color: $white;
    transition: all 0.2s ease;
    cursor: pointer;
    z-index: 1;
    &:active, &:focus {
        outline: none !important;
    }
    &.mini {
        width: 48px;
        height: 48px;
        padding: 12px;
        transform: rotate(-600deg);
        background: $primary;
    }
    &.like { 
        background: $title-grey !important;
        transform: rotate(0deg);
        position: absolute;
        right: 28%;
        bottom: -22px;
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
        i.unliked {
            display: block;
            color: $white;
            position: relative;
            z-index: 1;
        }
        i.liked {
            display: none;
            color: $white;
            position: relative;
            z-index: 1;
        }
        &.is-active {
            .like-overlay {
                transform: scale(1);
            }
            i.unliked {
                display: none;
            }
            i.liked {
                display: block;
            }
        }
        &.is-single {
            bottom: -34px;
        }
    }
    .like-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        .like-overlay {
            position: absolute;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            top: -36px;
            left: -24px;
            background: $red;
            transform: scale(0);
            transition: transform 0.4s;
            z-index: 0;
        }
    }
}


//Blog page media query (mobile)
@media (max-width: 768px) {
    .blog-section {
        padding: 5px !important;
    }
    .flex-card.is-post {
        &.is-pulled-top {
            margin-top: -80px;
        }
        .header .author-avatar {
            right: 20px !important;
            bottom: -30px !important;
            width: 60px !important;
            height: 60px !important;
            border: 3px solid $white !important;
            img {
                left: 0.5px;
                transform: scale(1);
            }
        }
    }
}

/* ==========================================================================
15. Blog post
========================================================================== */

//Blog sidebar search input
.control.is-blog-search {
    position: relative;
    margin-bottom: 20px;
    input {
        height: 60px;
        width: 100%;
        border: none;
        padding-left: 45px;
        border: 1px solid $fade-grey;
        transition: all 0.5s;
        &:focus {
            box-shadow: inset rgba(143,160,241,0.2) 0 0 0 1px, rgba(213,220,247,0.59) 0 10px 20px;
            border: 1px solid $primary;
            border-radius: 5px;
            + i {
                color: $primary;
            }
        }
        &:focus, &:active {
            outline: none;
        }
    }
    i {
        position: absolute;
        left: 15px;
        top: 22px;
        color: $placeholder;
        font-size: 20px;
        transition: color 0.5s;
    }
}

//Blog single post
.flex-card.is-full-post {
    border-radius: 6px;
    padding: 60px;
    &.has-sidebar {
        padding: 40px;
    }
    &.is-pulled-top {
        margin-top: -180px;
    }
    .post-meta {
        display: flex;
        justify-content: flex-start;
        padding: 10px 30px;
        margin: 0;
        border-bottom: 1px solid $fade-grey;
        img {
            width: 55px;
            height: 55px;
            border-radius: 50%;
        }
        .title-block {
            position: relative;
            padding: 0 20px;
            h2, h4 {
                margin-bottom: 0;
            }
            h2 {
                font-family: 'Nexa Bold', sans-serif;
                font-weight: 700; 
            }
            h4 {
                font-family: 'Nexa Light', sans-serif;
                color: $muted-grey;
                padding: 10px 0;
            }
            .like {
                right: 20px;
                &.is-full {
                    bottom: -32px;
                }
            }
        }
    }
    .post-body {
        padding: 10px 30px;
        .author-name {
            padding: 10px 0 5px 0;
            font-size: 90%;
            color: $title-grey;
            b a {
                color: $blue-grey;
                &:hover {
                    color: $primary;
                }
            }
        }
        p {
            font-size: 16px;
        }
        .timestamp {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 90%;
            color: $title-grey;
            padding: 5px 0 10px 0;
            i {
                font-size: 16px;
                color: $primary;
                margin-right: 5px;
            }
        }
        h5 {
            font-family: 'Nexa Bold', sans-serif;
            font-size: 1.3rem;
            font-weight: 500;
            color: $blue-grey;
            padding: 10px 0;
            margin-bottom: 0;
        }
        .post-image {
            margin: 10px 0;
            border-radius: 10px;

        }
        hr {
            background-color: $fade-grey;
        }
        .share-post {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .share-text {
                font-weight: bold;
                color: $blue-grey;
            }
            .sharing-options {
                i {
                    font-size: 1.4rem;
                    margin: 0 5px;
                    cursor: pointer;
                    color: $title-grey;
                    &:hover {
                        &:first-child  {
                            color: $primary;
                        }
                        &:nth-child(2) {
                            color: $facebook;
                        }
                        &:nth-child(3) {
                            color: $twitter;
                        }
                        &:nth-child(4) {
                            color: $linkedin;
                        }
                        &:nth-child(5) {
                            color: $google-plus;
                        }
                        &:nth-child(6) {
                            color: $reddit;
                        }
                        &:nth-child(7) {
                            color: $tumblr;
                        }
                    }
                }
            }
        }
    }
}

//Comment count section
.comment-count {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-radius: 6px;
    i {
        font-size: 2rem;
        font-weight: 500;
        color: $title-grey;
    }
    .text {
        font-size: 1.1rem;
        font-weight: 400;
        color: $title-grey;
        margin-left: 20px;
        span.count-number {
            font-size: 1.1rem;
        }
    }
    button {
        margin-left: auto;
        color: $muted-grey !important;
    }
}

//Write comment card
.compose-card {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: unset;
    .control-wrapper {
        width: 100%;
        padding-left: 20px;
        .textarea-button {
            background: $fade-grey;
        }
    }
}

//Comments list
.comments-list {
    border-radius: 6px;
    padding: 40px;
    .media {
        margin: 0 !important;
        padding: 10px 0 !important;
        border: none !important;
        .media-left img {
            border-radius: 50%;
        }
        small {
            color: $title-grey;
            .reply a {
                font-size: 95%;
                padding: 0 20px;
            }
        }
        small a {
            color: $title-grey;
            font-size: 16px;
            i {
                vertical-align: middle;
                font-size: 16px;
                margin: 0 5px;
                &:hover {
                    color: $red !important;
                }
            }
            &:hover {
                color: $primary;
                i {
                    color: $red;
                }
            }
        }
        .count {
            color: $title-grey;
            font-weight: 500;
        }
        .timestamp {
            display: block;
            font-size: 90%;
            color: $title-grey;
        }
        strong {
            font-family: 'Nexa Bold';
            font-weight: 600 !important;
        }
    }
}

.content p:not(:last-child) {
    margin-bottom: 0 !important;
}

//Blog sidebar widgets
.flex-card {
    .card-header {
        font-family: 'Nexa Bold', sans-serif;
        padding: 20px;
        font-size: 1.2rem;
        font-weight: 500;
        color: $blue-grey;
    }
    .card-panel {
        //Recent posts
        .recent-posts {
            .recent-post {
                padding: 10px 20px;
                border-bottom: 1px solid $fade-grey;
                .post-title {

                }
                .post-title a {
                    color: $blue-grey;
                    font-weight: 600;
                    &:hover {
                        color: $primary;
                    }
                }
                .post-meta {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                    }
                }
                span {
                    display: block;
                    font-size: 90%;
                    color: $title-grey;
                }
            }
        }
        //Latest comments
        .latest-comments {
            .latest-comment {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 16px 20px;
                border-bottom: 1px solid $fade-grey;
                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                .comment-info {
                    .name a {
                        color: $blue-grey;
                        font-weight: 600;
                        &:hover {
                            color: $primary;
                        }
                    }
                    .post-ref a {
                        font-size: 90%;
                        color: $title-grey;
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
        //Archives and categories
        .archives, .post-categories {
            .archived-month, .post-category {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 20px;
                border-bottom: 1px solid $fade-grey;
                span a {
                    color: $blue-grey;
                    font-weight: 600;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        //Tags
        .tag {
            margin: 5px;
        }
    }
}

//Flex title
.title.flex-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span.material-icons {
        font-size: 2.4rem;
        padding: 8px;
        border: 2px solid $white;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            border: 2px solid $primary;
            background: $primary;
            color: $white;
        }
    }
}

//Single post media query (mobile)
@media (max-width: 768px) {
    .flex-card.is-full-post {
        &.is-pulled-top {
            margin-top: -80px;
        }
        .post-body {
            padding: 20px !important;
        }
    }
    .title.flex-title {
        justify-content: center;
    }
}

/* ==========================================================================
16. Media queries
========================================================================== */

//Mobile
@media (max-width: 767px) {
    .title.main-title {
        font-size: 3rem !important;
    }
    .flex-card {
        &.is-post {
            h2 {
                font-size: 1.3rem !important;
            }
        }
        &.is-full-post {
            padding: 10px !important;
            .post-body {
                padding: 20px 10px !important;
            }
        }
    }
    .post-meta {
        margin: 0 !important;
        padding: 10px !important;
        .title-block {
            padding: 0 !important;
            h2 {
                font-size: 1.5em !important;
            }
        }
    }
    .post-body {
        padding: 20px 10px !important;
    }
    .compose-card {
        .control-wrapper {
            padding: 0;
        }
    }
}


/* kit-6 */
//Feature boxes
.is-feature {
    border: 1px solid lighten($fade-grey, 3%);
    border-radius: 6px;
    box-shadow: inset rgba(143,160,241,0.09) 0 0 0 1px, rgba(213,220,247,0.22) 0 10px 20px;
    .icon-container {
        height: 70px;
        width: 70px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        &.is-first {
            background: #e0ecff;
        }
        &.is-second {
            background: #f6dffb;
        }
        &.is-third {
            background: #dffee4;
        }
        img {
            height: 40px;
            width: 40px;
            display: block;
        }
    }
    .content-container {
        h3 {
            padding: 10px 0;
            font-family: 'Nexa Bold', sans-serif;
        }
        p {
            font-size: .95rem;
            font-family: 'Roboto', sans-serif;
            color: $muted-grey;
        }
    }
}

//
.featured-drawing {
    max-width: 450px;
    display: block;
    margin: 0 auto;
}

//Icon feature
.icon-feature {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &:hover {
        .icon-block .icon-inner {
            border-bottom-color: $secondary;
        }
        .feature-text {
            h3 {
                color: $secondary;
            }
        }
    }
    .icon-block {
        border-radius: 6px;
        height: 100px;
        width: 100px;
        .icon-inner {
            box-shadow:  0px 5px 43px rgba(0, 0, 0, 0.18) !important;
            height: 100px;
            width: 100px;
            display: flex;
            border-radius: 6px;
            justify-content: center;
            align-items: center;
            border: 3px solid transparent;
            transition: all .3s;
            img {
                height: 46px;
                width: 46px;
            }
        }
    }
    .feature-text {
        margin: 0 20px;
        h3 {
            color: $blue-grey;
            font-family: 'Nexa Bold', sans-serif;
            font-weight: 500;
            font-size: 1.2rem;
            margin-bottom: 6px;
            transition: all .3s;
        }
        p {
            color: $muted-grey;
        }
    }
}

// custom Tobi

/**/
.card-description {
    color: #000 !important;
}
.subtitle {
    color: #000 !important;
}
.hero-body .icon-subtitle i, section .icon-subtitle i {
    font-size: 2.4rem;
    color: #f00;
}
.section .title-divider {
    margin: 10px 0;
    width: 80px;
    height: 5px;
    background: #000;
}

body {
    color: #000;
}
.button{
    white-space: unset;
    height: auto;
    padding: 12px 20px;
}
.button.button-signup {
    padding: 12px 35px !important;
}
.button.is-link {
    background-color: #f5f5f5 !important;
    border-color: transparent;
}
/* navbar */
/*make scrollable*/
@media only screen and (max-width: 770px) {
    .navbar > .container {
        max-height: 100vh;
        overflow-y: auto;
    }
}
@media only screen and (max-width: 1020px) {
    .navbar-start > .navbar-item > .navbar-link{
        display: none;
    }
    .navbar-start > a.navbar-item{
        font-weight: bold;
        color: #000;
    }
}
.navbar-item.is-flex {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}
.navbar .navbar-menu .navbar-link, .navbar .navbar-menu .navbar-item, .navbar .navbar-menu .navbar-dropdown {
    text-align: left !important;
}
.navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: transparent;
}

.navbar-brand img{
    height: 35px !important;
    max-height: 35px;
    margin-bottom: 5px;
}

.button-signup:hover{
    background: #f00 !important;
    color: #fff !important;
}

/* mobil scroll fix */
@media all and (max-width: 1090px){
    .navbar-end .navbar-item{
        padding-bottom: 100px;
    }
}

/* MODAL */
.disable-scroll{
    overflow: hidden;
}
body.is-fixed {
    width: 100%;
}
.modal{
    z-index: 10001;
}
.modal-background {
    background: none !important;
    background-color: rgba(10,10,10,0.9) !important;
}
.modal.is-active{
    backdrop-filter: blur(5px);
}

/* rating modal */
.modal-card, .modal-content {
    position: fixed;
}

.modal-content{
    -webkit-overflow-scrolling: touch !important;
    overflow-y: auto !important;
}
#rating-modal .modal-content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
}
@media all and (min-width: 400px){
    #rating-modal .modal-content {
        width: 900px !important;
        max-width: 95%;
        max-height: 95%
    }
}

/* cookie consent */
#ct-ultimate-gdpr-cookie-popup {
    padding: 15px !important;
}
#ct-ultimate-gdpr-cookie-accept, #ct-ultimate-gdpr-cookie-change-settings, #ct-ultimate-gdpr-cookie-read-more {
    font-weight: normal !important;
}
#ct-ultimate-gdpr-cookie-accept{
    font-weight: bold !important;
}

/*notification minified bug fixed: hidden because minified animation renamed -> show always*/
.iziToast.iziToast-animateInside .iziToast-buttons-child, .iziToast.iziToast-animateInside .iziToast-icon, .iziToast.iziToast-animateInside .iziToast-inputs-child, .iziToast.iziToast-animateInside .iziToast-message, .iziToast.iziToast-animateInside .iziToast-title {
    opacity: 1;
}
/* tags */
.tagsinput input{
    width: auto !important;
    font-family: inherit !important;
}
.tagsinput{
    padding: 5px 10px !important;
    border: 1px solid #dbdbdb !important;
    line-height: 33px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.06);
}

/**/
.property-card .card-image .image .price {
    position: absolute;
    left: 0;
    top: 0;
    margin-top:-8%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    webkit-transform: scale(0.94);
    transform: scale(0.94);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    color: #FFFFFF;
    line-height: 2em;
    font-size: 1.7em;
    z-index: 1;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.property-card .card-image {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.property-card:hover .card-image {
    -moz-transform: scale(1.03);
    -ms-transform: scale(1.03);
    -webkit-transform: scale(1.03);
    transform: scale(1.03) !important;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.property-card .title {
    font-size: 21px;
}
.property-card .short-details {
    position: absolute;
    bottom: 20%;
    z-index: 2;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    left: 20px;
}

/**/
.chosen-container .chosen-results li.highlighted {
    color: #f00;
}
.chosen-container .chosen-results {
    color: #888;
}
.chosen-container-single .chosen-single span {
    color: #000;
}

/**/
div.tagsinput span.tag {
    margin-bottom: 0 !important;
    height: auto !important;
}

/* chatra */
#chatra:not(.chatra--expanded)::after {
    font-family: iconsmind;
    speak: none;
    font-style: normal;
    font-weight: bolder;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    content: "\ec99";
    color: #fff;
    font-size: 28px;
    text-align: center;
    vertical-align: middle;
    line-height: 54px;
    top: -50%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    display: block;
    width: 85%;
    height: 85%;
    background-color: #f00;
    border-radius: 50%;
    pointer-events: none;
}